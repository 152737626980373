.alert {
  padding: 0.375rem 0.75rem;
  margin: 0 auto;
  border-color: transparent;
  border-radius: var(--borderRadius);
  width: var(--fluid-width);
  max-width: var(--fixed-width);
  text-align: center;
  text-transform: capitalize;
}

.alert-danger {
  color: var(--red-dark);
  background: var(--red-light);
}
.alert-success {
  color: var(--green-dark);
  background: var(--green-light);
}
.alert {
  margin-top: 3rem;
}
h4 {
  text-align: center;
}
p {
  margin: 0;
  text-align: center;
}
.btn {
  margin-bottom: 1.5rem;
}
.register-link,
.reset-link {
  display: inline-block;
  margin-left: 0.25rem;
  text-transform: capitalize;
  color: var(--primary-500);
  cursor: pointer;
}
.register-link:hover {
  color: var(--primary-300);
}
.reset-link {
  margin-top: 0.25rem;
}
.btn:disabled {
  cursor: not-allowed;
}
